import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ObLotteryService {
  constructor() {}
  // 固定写死code,顺序以这里为主，derrick保证正式线与sit环境标签code一致
  MENU_DATA = [
    '1384751691420293', //即开彩
    '1384752445117061', //PK10
    '1384753443443333', //时时彩
    '1384754031301253', //六合彩
    '1384757409321605', //11选5
    '1384757928350341', //快三
    '1384759301673605', //3D
    '1384759752430213', //快乐8
    '1384760671752837', //双色球
    '1384761298473605', //万字4D
    '1384430765885445', //越南彩
    '1384431996389381', //骰宝
    '1384764990001797', //英雄召唤
    '1384765577286277', //低频彩
  ];
}
