import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { PopupApi } from '../../apis/popup.api';
import { LayoutService } from '../../service/layout.service';
import { LocalStorageService } from '../../service/localstorage.service';
import { PopupService } from '../../service/popup.service';
import { BannerPopupComponent } from './banner-popup.component';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BannerPopupService {
  constructor(
    private localStorageService: LocalStorageService,
    private layout: LayoutService,
    private popupService: PopupService,
    private popupApi: PopupApi
  ) {}

  isH5 = toSignal(this.layout.isH5$);

  onShowPopupForBeforeLogin() {
    let isHideBannerPopupBE = this.localStorageService.getValueByJsonParse('isHideBannerPopupBE');
    const lastTime = moment(this.localStorageService.getValueByJsonParse('isHideBannerPopupBETime'));
    const now = moment();

    // show again after 1 day
    if (now.diff(lastTime, 'days') > 0) {
      this.localStorageService.setValueByJsonStringify('isHideBannerPopupBE', false);
      isHideBannerPopupBE = false;
    }

    if (!isHideBannerPopupBE) {
      if (isHideBannerPopupBE === '') {
        this.localStorageService.setValueByJsonStringify('isHideBannerPopupBE', false);
      }

      this.popupApi
        .getPopupList()
        .pipe(untilDestroyed(this))
        .subscribe(list => {
          if (list.length > 0) {
            this.popupService.open(BannerPopupComponent, {
              speed: 'faster',
              position: this.isH5() ? { bottom: '0px' } : {},
              disableClose: true,
              data: {
                bannerList: list,
              },
            });
          }
        });
    }
  }

  onShowPopupForAfterLogin() {
    let isHideBannerPopupAF = this.localStorageService.getValueByJsonParse('isHideBannerPopupAF');
    const lastTime = moment(this.localStorageService.getValueByJsonParse('isHideBannerPopupAFTime'));
    const now = moment();

    // show again after 1 day
    if (now.diff(lastTime, 'days') > 0) {
      this.localStorageService.setValueByJsonStringify('isHideBannerPopupAF', false);
      isHideBannerPopupAF = false;
    }

    if (!isHideBannerPopupAF) {
      if (isHideBannerPopupAF === '') {
        this.localStorageService.setValueByJsonStringify('isHideBannerPopupAF', false);
      }

      this.popupApi
        .getPopupList()
        .pipe(untilDestroyed(this))
        .subscribe(list => {
          if (list.length > 0) {
            this.popupService.open(BannerPopupComponent, {
              speed: 'faster',
              position: this.isH5() ? { bottom: '0px' } : {},
              disableClose: true,
              data: {
                bannerList: list,
              },
            });
          }
        });
    }
  }

  checkBannerPopupWhenDifferentUser(curUid: string) {
    const preUid = this.localStorageService.getValueByJsonParse('uid');

    // clear banner popup status when user changed
    if (curUid !== preUid) {
      this.localStorageService.setValueByJsonStringify('isHideBannerPopupAF', false);
      this.localStorageService.setValueByJsonStringify('isHideBannerPopupAFTime', '');
    }

    this.localStorageService.setValueByJsonStringify('uid', curUid);
    this.onShowPopupForAfterLogin();
  }
}
